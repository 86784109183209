<template>
	<PositiveNegativeList></PositiveNegativeList>
</template>

<script>
import PositiveNegativeList from "@/components/shared/tools/knowledgeOrganizers/positiveNegative/List.vue";

export default {
	name: "PositiveNegativeListPage",

	components: {
		PositiveNegativeList,
	},

	created() {
		this.$eventBus.$on("ko-redirect-back", (payload) => {
			this.$router.push({
				name: "KnowledgeOrganizers",
			});
		});
	},

	beforeDestroy() {
		this.$eventBus.$off("ko-redirect-back");
	},
};
</script>
