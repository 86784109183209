<template>
	<div class="pa-2">
		<AppTable
			v-if="statements.length"
			:headers="headers"
			:items="items"
			:actions="actions"
			:operation="operation"
			@explore="exploreStatement"
			@create="addStatement()"
			@delete="executeDeletion"
		></AppTable>

		<!-- If no interviews are created -->
		<NoData
			v-else
			heading="app.no_statements_yet"
			@init="addStatement()"
		></NoData>

		<!-- Confirmation box for delete operation -->
		<TheConfirmation
			v-model="delete_dialog"
			:dialog="delete_dialog"
			@cancel="delete_dialog = false"
			@confirm="deleteStatement()"
		></TheConfirmation>
	</div>
</template>

<script>
import { mapState } from "vuex";
import AppTable from "@/components/ui/AppTable.vue";
import NoData from "@/components/shared/NoData.vue";
import TheConfirmation from "@/components/layout/TheConfirmation";

export default {
	name: "PositiveNegativeList",

	data() {
		return {
			headers: [
				"app.statement_no",
				"app.title",
				"app.total_positive_points",
				"app.total_negative_points",
				"app.explore",
			],
			delete_dialog: false,
			selected_id: null,
		};
	},

	components: {
		AppTable,
		NoData,
		TheConfirmation,
	},

	mounted() {
		this.getStatements();
	},

	computed: {
		...mapState({
			statements: (state) => state.statement.list,
		}),

		headers() {
			return [
				"app.s_no",
				"app.title",
				"app.total_positive_points",
				"app.total_negative_points",
				"app.actions",
			];
		},

		items() {
			let result = [];
			this.statements.forEach((item) => {
				result.push([
					item.id,
					item.title,
					item.positive_points.length,
					item.negative_points.length,
				]);
			});

			return result;
		},

		actions() {
			return [
				{
					icon: "delete",
					color: "error",
					event_name: "delete",
				},
				{
					icon: "edit",
					color: "info",
					event_name: "explore",
				},
				{
					icon: this.$vuetify.rtl ? "left_arrow" : "right_arrow",
					color: "success",
					event_name: "explore",
				},
			];
		},

		operation() {
			return {
				event: "create",
			};
		},
	},

	methods: {
		async getStatements() {
			try {
				this.$loader.start();

				await this.$store.dispatch("statement/list");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async addStatement() {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch("statement/add", {
					title: null,
					positive_points: [{ title: null }, { title: null }],
					negative_points: [{ title: null }, { title: null }],
				});

				// Push to this new statement
				this.exploreStatement(response.id);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		exploreStatement(statement_id) {
			try {
				this.$loader.start();

				this.$router.push({
					name: "PositiveNegativeStatement",
					params: {
						id: statement_id,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		executeDeletion(statement_id) {
			try {
				this.selected_id = statement_id;

				this.delete_dialog = true;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async deleteStatement() {
			try {
				this.$loader.start();

				// Delete statement
				await this.$store.dispatch("statement/delete", {
					statement_id: this.selected_id,
				});

				this.delete_dialog = false;

				this.selected_id = null;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
